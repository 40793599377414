@font-face {
  font-family: 'SourceCodePro';
  src: url('./SourceCodePro.ttf') format('truetype');
  font-stretch: normal;
}

@font-face {
  font-family: 'SourceSans';
  src: url('./SourceSans.ttf') format('truetype');
  font-stretch: normal;
}

@font-face {
  font-family: 'SourceSansItalic';
  src: url('./SourceSansItalic.ttf') format('truetype');
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'SourceSerif';
  src: url('./SourceSerif.ttf') format('truetype');
  font-stretch: normal;
}
